var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { attrs: { id: "ag-grid-demo" } },
      [
        _c(
          "vx-card",
          {
            attrs: {
              title:
                _vm.$t(_vm.resources.OrderHistory.i18n) ||
                _vm.resources.OrderHistory.name
            }
          },
          [
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/4 mb-base" },
                [
                  _c("label", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.SinceWeek.i18n) ||
                          _vm.resources.SinceWeek.name
                      ) + ":"
                    )
                  ]),
                  _c("v-select", {
                    attrs: { label: "text", options: _vm.getDate },
                    model: {
                      value: _vm.start_date,
                      callback: function($$v) {
                        _vm.start_date = $$v
                      },
                      expression: "start_date"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/4 mb-base" },
                [
                  _c("label", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.UntilWeek.i18n) ||
                          _vm.resources.UntilWeek.name
                      ) + ":"
                    )
                  ]),
                  _c("v-select", {
                    attrs: { label: "text", options: _vm.getDate },
                    model: {
                      value: _vm.end_date,
                      callback: function($$v) {
                        _vm.end_date = $$v
                      },
                      expression: "end_date"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/6 mb-base mt-5" },
                [
                  _c(
                    "vs-button",
                    {
                      attrs: { color: "primary", type: "filled" },
                      on: {
                        click: function($event) {
                          return _vm.listOrders()
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.Search.i18n) ||
                            _vm.resources.Search.name
                        )
                      )
                    ]
                  )
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "flex flex-wrap justify-between items-center" },
              [
                _c(
                  "div",
                  {
                    staticClass: "mb-4 md:mb-0 mr-4 ag-grid-table-actions-left"
                  },
                  [
                    _c(
                      "vs-dropdown",
                      {
                        staticClass: "cursor-pointer",
                        attrs: { "vs-trigger-click": "" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                          },
                          [
                            _c("span", { staticClass: "mr-2" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.currentPage * _vm.paginationPageSize -
                                    (_vm.paginationPageSize - 1)
                                ) +
                                  " - " +
                                  _vm._s(
                                    _vm.orders.length -
                                      _vm.currentPage * _vm.paginationPageSize >
                                      0
                                      ? _vm.currentPage * _vm.paginationPageSize
                                      : _vm.orders.length
                                  ) +
                                  " of " +
                                  _vm._s(_vm.orders.length)
                              )
                            ]),
                            _c("feather-icon", {
                              attrs: {
                                icon: "ChevronDownIcon",
                                svgClasses: "h-4 w-4"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "vs-dropdown-menu",
                          [
                            _c(
                              "vs-dropdown-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.gridApi.paginationSetPageSize(20)
                                  }
                                }
                              },
                              [_c("span", [_vm._v("20")])]
                            ),
                            _c(
                              "vs-dropdown-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.gridApi.paginationSetPageSize(50)
                                  }
                                }
                              },
                              [_c("span", [_vm._v("50")])]
                            ),
                            _c(
                              "vs-dropdown-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.gridApi.paginationSetPageSize(
                                      100
                                    )
                                  }
                                }
                              },
                              [_c("span", [_vm._v("100")])]
                            ),
                            _c(
                              "vs-dropdown-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.gridApi.paginationSetPageSize(
                                      150
                                    )
                                  }
                                }
                              },
                              [_c("span", [_vm._v("150")])]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-wrap items-center justify-between ag-grid-table-actions-right"
                  },
                  [
                    _c("vs-input", {
                      staticClass: "mb-4 md:mb-0 mr-4",
                      attrs: {
                        placeholder:
                          _vm.$t(_vm.resources.Search.i18n) ||
                          _vm.resources.Search.name
                      },
                      on: { input: _vm.updateSearchQuery },
                      model: {
                        value: _vm.searchQuery,
                        callback: function($$v) {
                          _vm.searchQuery = $$v
                        },
                        expression: "searchQuery"
                      }
                    }),
                    _c(
                      "vs-button",
                      {
                        staticClass: "mb-4 md:mb-0",
                        on: {
                          click: function($event) {
                            return _vm.gridApi.exportDataAsCsv()
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.ExportAs.i18n) ||
                              _vm.resources.ExportAs.name
                          ) + " CVS"
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            ),
            _c("ag-grid-vue", {
              staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
              attrs: {
                gridOptions: _vm.gridOptions,
                columnDefs: _vm.columnDefs,
                defaultColDef: _vm.defaultColDef,
                rowData: _vm.orders,
                rowSelection: "single",
                colResizeDefault: "shift",
                animateRows: true,
                floatingFilter: true,
                pagination: true,
                paginationPageSize: _vm.paginationPageSize,
                suppressPaginationPanel: true
              },
              on: { "row-selected": _vm.onRowSelected }
            }),
            _c("vs-pagination", {
              attrs: { total: _vm.totalPages, max: _vm.maxPageNumbers },
              model: {
                value: _vm.currentPage,
                callback: function($$v) {
                  _vm.currentPage = $$v
                },
                expression: "currentPage"
              }
            })
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "demo-alignment" },
      [
        _c(
          "vs-popup",
          {
            attrs: {
              fullscreen: "",
              title:
                _vm.$t(_vm.resources.Invoice.i18n) ||
                _vm.resources.Invoice.name,
              active: _vm.popupActive
            },
            on: {
              "update:active": function($event) {
                _vm.popupActive = $event
              }
            }
          },
          [
            _c("div", { attrs: { id: "invoice-page" } }, [
              _c("div", { staticClass: "vx-row leading-loose p-base" }, [
                _c("div", { staticClass: "vx-col w-full md:w-1/2 mt-base" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/logo/logo_vt.png"),
                      alt: "vuesax-logo"
                    }
                  })
                ]),
                _c(
                  "div",
                  { staticClass: "vx-col w-full md:w-1/2 text-right" },
                  [
                    _c("h1", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.Invoice.i18n) ||
                            _vm.resources.Invoice.name
                        )
                      )
                    ]),
                    _c("div", { staticClass: "invoice__invoice-detail mt-6" }, [
                      _c("h6", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.OrderNumber.i18n) ||
                              _vm.resources.Invoice.name
                          )
                        )
                      ]),
                      _c("p", [_vm._v(_vm._s(_vm.invoice.odh_number))]),
                      _c("h6", { staticClass: "mt-4" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.OrderDate.i18n) ||
                              _vm.resources.OrderDate.name
                          )
                        )
                      ]),
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm.invoice.ord_ent_dt) +
                            _vm._s(_vm.invoice.ord_ent_tm)
                        )
                      ])
                    ])
                  ]
                ),
                _c("div", { staticClass: "vx-col w-full md:w-1/2 mt-12" }, [
                  _c("h5", [
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.BillTo.i18n) ||
                            _vm.resources.BillTo.name
                        ) + ":"
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "invoice__recipient-info my-4" }, [
                    _c("p", [_vm._v(_vm._s(_vm.invoice.DST_Display_Name_1))]),
                    _c("p", [_vm._v(_vm._s(_vm.invoice.DST_Addr))]),
                    _c("p", [_vm._v(_vm._s(_vm.invoice.addressLine2))]),
                    _c("p", [_vm._v(_vm._s(_vm.invoice.DST_Addr_3))])
                  ]),
                  _c("div", { staticClass: "invoice__recipient-contact" }, [
                    _c(
                      "p",
                      { staticClass: "flex items-center" },
                      [
                        _c("feather-icon", {
                          attrs: { icon: "MailIcon", svgClasses: "h-4 w-4" }
                        }),
                        _c("span", { staticClass: "ml-2" }, [
                          _vm._v(_vm._s(_vm.invoice.DST_email_addr))
                        ])
                      ],
                      1
                    ),
                    _c(
                      "p",
                      { staticClass: "flex items-center" },
                      [
                        _c("feather-icon", {
                          attrs: { icon: "PhoneIcon", svgClasses: "h-4 w-4" }
                        }),
                        _c("span", { staticClass: "ml-2" }, [
                          _vm._v(_vm._s(_vm.invoice.DST_Home_Phone))
                        ])
                      ],
                      1
                    )
                  ])
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "vx-col w-full md:w-1/2 mt-base text-right mt-12"
                  },
                  [
                    _c("h5", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.ShipTo.i18n) ||
                              _vm.resources.ShipTo.name
                          ) + ":"
                        )
                      ])
                    ]),
                    _c("h5", [_vm._v(_vm._s(_vm.invoice.ship_to_name))]),
                    _c("div", { staticClass: "invoice__company-info my-4" }, [
                      _c("p", [_vm._v(_vm._s(_vm.invoice.ship_to_addr_1))]),
                      _c("p", [_vm._v(_vm._s(_vm.invoice.ship_to_addr_3))]),
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm.invoice.ship_to_city) +
                            _vm._s(
                              " (Postal code:" + _vm.invoice.ship_to_zip + ")"
                            )
                        )
                      ]),
                      _c("p")
                    ]),
                    _c("div", { staticClass: "invoice__company-contact" }, [
                      _c(
                        "p",
                        { staticClass: "flex items-center justify-end" },
                        [
                          _c("feather-icon", {
                            attrs: { icon: "MailIcon", svgClasses: "h-4 w-4" }
                          }),
                          _c("span", { staticClass: "ml-2" }, [
                            _vm._v(_vm._s(_vm.invoice.ship_to_email))
                          ])
                        ],
                        1
                      ),
                      _c(
                        "p",
                        { staticClass: "flex items-center justify-end" },
                        [
                          _c("feather-icon", {
                            attrs: { icon: "PhoneIcon", svgClasses: "h-4 w-4" }
                          }),
                          _c("span", { staticClass: "ml-2" }, [
                            _vm._v(_vm._s(_vm.invoice.ship_to_phone))
                          ])
                        ],
                        1
                      )
                    ])
                  ]
                )
              ]),
              !_vm.invoice
                ? _c("div", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.NoAvailableData.i18n) ||
                          _vm.resources.NoAvailableData.name
                      )
                    )
                  ])
                : _c(
                    "div",
                    { staticClass: "p-base" },
                    [
                      _c(
                        "vs-table",
                        {
                          attrs: { hoverFlat: "", data: _vm.invoice },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(ref) {
                                var data = ref.data
                                return [
                                  _vm._l(data.odl, function(tr, indextr) {
                                    return _c(
                                      "vs-tr",
                                      { key: indextr },
                                      [
                                        _c("vs-td", [
                                          _vm._v(_vm._s(indextr + 1))
                                        ]),
                                        _c(
                                          "vs-td",
                                          {
                                            attrs: {
                                              data: data.odl[indextr].item_code
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                data.odl[indextr].item_code
                                              )
                                            )
                                          ]
                                        ),
                                        _c(
                                          "vs-td",
                                          {
                                            attrs: {
                                              data:
                                                data.odl[indextr].qty_shipped
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                data.odl[indextr].qty_shipped
                                              )
                                            )
                                          ]
                                        ),
                                        _c(
                                          "vs-td",
                                          {
                                            attrs: {
                                              data:
                                                data.odl[indextr].qty_ordered
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                data.odl[indextr].qty_ordered
                                              )
                                            )
                                          ]
                                        ),
                                        _c(
                                          "vs-td",
                                          {
                                            attrs: {
                                              data:
                                                data.odl[indextr].item_name_1
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                data.odl[indextr].item_name_1
                                              )
                                            )
                                          ]
                                        ),
                                        _c(
                                          "vs-td",
                                          {
                                            attrs: {
                                              data: data.odl[indextr].price_2
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(data.odl[indextr].price_2)
                                            )
                                          ]
                                        ),
                                        _c(
                                          "vs-td",
                                          { attrs: { data: data.pv_amount } },
                                          [_vm._v(_vm._s(data.pv_amount))]
                                        ),
                                        _c(
                                          "vs-td",
                                          {
                                            attrs: {
                                              data:
                                                data.odl[indextr].sales_price
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                data.odl[indextr].sales_price
                                              )
                                            )
                                          ]
                                        ),
                                        _c(
                                          "vs-td",
                                          {
                                            attrs: { data: data.sales_amount }
                                          },
                                          [_vm._v(_vm._s(data.sales_amount))]
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  _c(
                                    "vs-tr",
                                    [
                                      _c("vs-td"),
                                      _c("vs-td"),
                                      _c("vs-td"),
                                      _c("vs-td"),
                                      _c("vs-td"),
                                      _c("th", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              _vm.resources.TotalVolumen.i18n
                                            ) || _vm.resources.TotalVolumen.name
                                          )
                                        )
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(_vm.invoice.pv_amount))
                                      ]),
                                      _c("th", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              _vm.resources.Subtotal.i18n
                                            ) || _vm.resources.Subtotal.name
                                          )
                                        )
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(_vm.invoice.sales_amount))
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "template",
                            { slot: "thead" },
                            [
                              _c("vs-th", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(_vm.resources.Item.i18n) ||
                                      _vm.resources.Item.name
                                  )
                                )
                              ]),
                              _c("vs-th", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(_vm.resources.Code.i18n) ||
                                      _vm.resources.Code.name
                                  )
                                )
                              ]),
                              _c("vs-th", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(_vm.resources.QtyShip.i18n) ||
                                      _vm.resources.QtyShip.name
                                  )
                                )
                              ]),
                              _c("vs-th", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(_vm.resources.QtyOrder.i18n) ||
                                      _vm.resources.QtyOrder.name
                                  )
                                )
                              ]),
                              _c("vs-th", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(_vm.resources.Description.i18n) ||
                                      _vm.resources.Description.name
                                  )
                                )
                              ]),
                              _c("vs-th", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(_vm.resources.Volumen.i18n) ||
                                      _vm.resources.Volumen.name
                                  )
                                )
                              ]),
                              _c("vs-th", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(_vm.resources.TotalVolumen.i18n) ||
                                      _vm.resources.TotalVolumen.name
                                  )
                                )
                              ]),
                              _c("vs-th", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(_vm.resources.Price.i18n) ||
                                      _vm.resources.Price.name
                                  )
                                )
                              ]),
                              _c("vs-th", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(_vm.resources.Total.i18n) ||
                                      _vm.resources.Total.name
                                  )
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        2
                      ),
                      _c("div", { staticClass: "vx-row" }, [
                        _c(
                          "div",
                          {
                            staticClass: "vx-col w-full lg:w-1/2 mb-base p-base"
                          },
                          [
                            _c("h6", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(_vm.resources.Comments.i18n) ||
                                    _vm.resources.Comments.name
                                ) + ":"
                              )
                            ]),
                            _c("p", [_vm._v(_vm._s(_vm.invoice.comment_1))]),
                            _c("p", [_vm._v(_vm._s(_vm.invoice.comment_2))]),
                            _c("p", [_vm._v(_vm._s(_vm.invoice.comment_3))]),
                            _c("p", [_vm._v(_vm._s(_vm.invoice.comment_4))]),
                            _c("p", [_vm._v(_vm._s(_vm.invoice.comment_5))]),
                            _c("p", [_vm._v(_vm._s(_vm.invoice.comment_6))]),
                            _c("br"),
                            _c("h6", [_c("strong", [_vm._v("Warehouse 2")])]),
                            _c("p", [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.invoice.ord_whs_nm1))
                              ])
                            ]),
                            _c("p", [_vm._v(_vm._s(_vm.invoice.ord_whs_adr1))]),
                            _c("p", [_vm._v(_vm._s(_vm.invoice.ord_whs_adr2))]),
                            _c("p", [_vm._v(_vm._s(_vm.invoice.ord_whs_adr3))])
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "vx-col w-full lg:w-1/2 mb-base p-base"
                          },
                          [
                            _c(
                              "table",
                              {
                                staticClass: "w-3/4 ml-auto mt-3",
                                attrs: { hoverFlat: "" }
                              },
                              [
                                _c("vs-tr", [
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(_vm.resources.Tax.i18n) ||
                                          _vm.resources.Tax.name
                                      ) +
                                        " (" +
                                        _vm._s(
                                          _vm.$t(_vm.resources.Taxable.i18n) ||
                                            _vm.resources.Taxable.name
                                        ) +
                                        " 0.00 @ " +
                                        _vm._s(_vm.invoice.tax_percent) +
                                        "%)"
                                    )
                                  ]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(_vm.invoice.total_taxable_amount)
                                    )
                                  ])
                                ]),
                                _c("vs-tr", [
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          _vm.resources.ShippingAndHandling.i18n
                                        ) ||
                                          _vm.resources.ShippingAndHandling.name
                                      ) +
                                        " (" +
                                        _vm._s(_vm.invoice.ord_shv_nm) +
                                        ")"
                                    )
                                  ]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(_vm.invoice.total_invoice_amt)
                                    )
                                  ])
                                ]),
                                _c("vs-tr", [
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          _vm.resources.TotalAmountDue.i18n
                                        ) || _vm.resources.TotalAmountDue.name
                                      )
                                    )
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.invoice.sales_amount))
                                  ])
                                ])
                              ],
                              1
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  ),
              _c("div", { staticClass: "invoice__footer text-right p-base" }, [
                _c("h5", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.PaymentsReceived.i18n) ||
                          _vm.resources.PaymentsReceived.name
                      )
                    )
                  ])
                ]),
                _c("p", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.ReceiptAmount.i18n) ||
                          _vm.resources.ReceiptAmount.name
                      )
                    )
                  ]),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.invoice.amount_received) +
                      "\n          "
                  )
                ]),
                _c("p", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.TotalCashReceipts.i18n) ||
                          _vm.resources.TotalCashReceipts.name
                      )
                    )
                  ]),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.invoice.sales_amount) +
                      "\n          "
                  )
                ]),
                _c("p", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.InvoceBalance.i18n) ||
                          _vm.resources.InvoceBalance.name
                      )
                    )
                  ]),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.invoice.invoice_balance) +
                      "\n          "
                  )
                ])
              ]),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-full text-right" },
                  [
                    _c(
                      "vs-button",
                      {
                        attrs: { color: "rgb(174,174,174)", type: "filled" },
                        on: {
                          click: function($event) {
                            _vm.popupActive = false
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.Close.i18n) ||
                              _vm.resources.Close.name
                          )
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }